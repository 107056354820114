import React, { Component } from 'react';
import { ServicesTable } from './components/ServicesTable.js';
import './App.css';

class App extends Component {
    render() {
        const services = [
            {
                name: 'major-dental',
                label: 'Major dental',
            },
            {
                name: 'optical',
                label: 'Optical'
            },
            {
                name: 'physio',
                label: 'Physio'
            },
            {
                name: 'podiatry',
                label: 'Podiatry'
            },
            {
                name: 'pharmacy',
                label: 'Pharmacy'
            },
            {
                name: 'chiro-osteo',
                label: 'Chiro/Osteo'
            },
            {
                name: 'remedial-massage',
                label: 'Remedial massage'
            }];

        return (
            <ServicesTable services={services} />
        );
    }
}
export default App;
