import React from 'react';

export class Button extends React.Component{

    render() {
        // the checkedServices are available as a property to the Button so it can display appropriately
        const checkedArray = [...this.props.checkedServices.values()];
        let checkedCount = 0;
        checkedArray.forEach(function(e){
            if (e === true) {
                checkedCount++;
            }
        });
        if (checkedCount < 2) {
            return <div className="error">Please select 2 or more services</div>;
        }else if(checkedCount > 6){
            return <div className="error">Please select 5 or fewer services</div>;
        }else{
            return <button className="btn_reg" type="submit">{this.props.label}</button>;
        }
    }
}