import React from 'react';
import { ServiceCb } from './ServiceCb.js';
import { Button } from './Button.js';

export class ServicesTable extends React.Component{

    constructor(props){
        super(props);
        // if the local storage holds a value for checkedServices load it into state, default create empty map
        this.state = {
            checkedServices: new Map(JSON.parse(localStorage.getItem('checkedServices')))
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        // receives the change events from the checkboxes
        // applies the value to the checkedServices map
        // updates local storage
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedServices: prevState.checkedServices.set(item, isChecked) }));
    }

    render(){
        // store the checkedServices in the localstorage
        localStorage.setItem('checkedServices', JSON.stringify([...this.state.checkedServices]));
        return (
            <div>
                <ul className="o_servicesList hasOptions">
                {this.props.services.map((service, i) => (
                    <li key={'option_'+i} className={this.state.checkedServices.get(service.name) ? 'selected' : ''}><div className="chk"><ServiceCb isChecked={this.state.checkedServices.get(service.name) ? 'checked' : ''} id={"cb_"+service.name} name={service.name} label={service.label} changeHandler={this.handleChange} /></div></li>
                ))}
                </ul>
                <Button label="Get a quote" checkedServices={this.state.checkedServices} />
            </div>
        );
    }
}