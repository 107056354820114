import React from 'react';

export class ServiceCb extends React.Component{
    constructor(props){
        super(props);
        this.state = {checked : this.props.isChecked};
    }
    render(){
        return (
            <label><input checked={this.props.isChecked} type="checkbox" name={this.props.name} value={this.props.label} onChange={this.props.changeHandler} />{this.props.label}</label>
        )
    }
}